import React, { useState, useEffect } from "react";
import styled from "styled-components";
import WinrateBar from "./WinrateBar";
import useRatings from "../hooks/useRatings";
import useStorePlayers from "../hooks/useStorePlayers";
import copyArray from "../utils/process";

const StyledGameContainer = styled.div`
  display: grid;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
  overflow-y: auto;
  grid-template-columns: 1fr 1fr;
`;

const StyledGameRows = styled.div`
  overflow-y: scroll;
  height: 100%;
  grid-template-columns: subgrid;
  height: ${p => (p.hasPlayerContainer ? `calc(80vh - 68px)` : `80vh`)};
  -webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

const StyledPlayerRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid var(--grey);
  cursor: var(--cursor);
  :hover {
    background: #1a1a1a;
  }
`;

const StyledPlayerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 3px;
  height: minmax(auto, 200px);
  border: 1px solid var(--grey);
  padding: 1rem;
`;

export default function Stats({ data }) {
  const [state, dispatch] = useStorePlayers({
    data: data,
  });
  const [ratings, createRatings] = useRatings();
  const [statsRatings, setStatsRatings] = useState();

  useEffect(() => {
    if (ratings.length === 0) {
      createRatings(data);
    }
  }, []);

  useEffect(() => {
    const names = ratings.map(entry => {
      return entry.name;
    });
    setStatsRatings(names.sort());
  }, [ratings.length]);

  const playerIndex = player =>
    ratings.findIndex(element => element.name === player);

  if (ratings.length > 0) {
    return (
      <StyledGameContainer>
        <div style={{ height: "80vh" }}>
          <StyledPlayerContainer>
            <div>{state.player}</div>
            <div>{ratings[playerIndex(state.player)].mu.toFixed(2)}</div>
            <div style={{ gridColumn: "span 2" }}>
              <WinrateBar
                wins={ratings[playerIndex(state.player)].wins}
                losses={ratings[playerIndex(state.player)].losses}
              />
            </div>
          </StyledPlayerContainer>

          <StyledGameRows hasPlayerContainer={true}>
            {statsRatings &&
              statsRatings.map(player => {
                return (
                  <StyledPlayerRow
                    key={player}
                    onClick={() => dispatch({ type: "PLAYER", player: player })}
                    style={{ "--cursor": "pointer" }}
                  >
                    {player}
                  </StyledPlayerRow>
                );
              })}
          </StyledGameRows>
        </div>
        <div style={{ height: "80vh" }}>
          <StyledGameRows hasPlayerContainer={false}>
            {Object.keys(ratings[playerIndex(state.player)].matchups)
              .sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              })
              .map(player => {
                if (player !== "wins" && player !== "losses") {
                  return (
                    <StyledPlayerRow key={player}>
                      {player}
                      <WinrateBar
                        wins={
                          ratings[playerIndex(state.player)].matchups[player]
                            .wins
                        }
                        losses={
                          ratings[playerIndex(state.player)].matchups[player]
                            .losses
                        }
                      />
                    </StyledPlayerRow>
                  );
                }
                return null;
              })}
          </StyledGameRows>
        </div>
      </StyledGameContainer>
    );
  } else {
    return null;
  }
}
